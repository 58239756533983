import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path:'/dashboard',
    name:'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path:'/orden-dia',
    name: 'Ordendia',
    component: () => import('../views/Ordendia.vue')
  },
  {
    path:'/orden-dia-42',
    name: 'Ordendia42',
    component: () => import('../views/Ordendia42.vue')
  },
  {
    path:'/orden-dia-43',
    name: 'Ordendia43',
    component: () => import('../views/Ordendia43.vue')
  },
  {
    path:'/marco-juridico',
    name: 'MarcoJuridico',
    component: () => import('../views/Marcojuridico.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
