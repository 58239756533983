import { createStore } from 'vuex'

export default createStore({
  state: {
    nombre:'',
    dependencia:''
  },
  mutations: {
    obtenerUsuaria(state,payload){
      state.nombre = payload.nombre
      state.dependencia = payload.dependencia
    }
  },
  actions: {
    guardarUsuaria({ commit }, payload){
      commit('obtenerUsuaria',payload)
    }
  },
  modules: {
  }
})
