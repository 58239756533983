<template>
    <div class="barraIMM">
        <div class="logo-imm-menu">
            <span> Sistema Estatal para la Prevención, Atención, Sanción y Erradicación de las Violencias Contra las Mujeres</span>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logos:{
                immg:''
            }
        }
    },
}
</script>