<template>
  <div id="nav">
    <BarraIMM></BarraIMM>
  </div>
  <router-view/>
</template>

<script>
import BarraIMM from './components/BarraImm.vue'
export default {
  components:{
    BarraIMM
  }
}
</script>

<style>
#nav{
  width:100%;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
